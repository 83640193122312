/** @format */

import { Component, OnInit } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { ICam } from 'src/app/models/cam.interface'
import { AppStateService } from 'src/app/state/app-state.service'
import { UserService } from 'src/service/user/user.service'

@Component({
	selector: 'rdsg-cam-mgmt',
	templateUrl: './cam-mgmt.page.html',
	styleUrls: ['./cam-mgmt.page.scss']
})
export class CamMgmtPage implements OnInit {
	public cameras$: Observable<any>

	public camerasSubject: BehaviorSubject<any> = new BehaviorSubject([])
	public cameras: ICam[] = []

	constructor(private readonly userAuth: UserService) {
		this.cameras$ = this.camerasSubject.asObservable()
	}

	async ngOnInit() {
		await this.getCameras()
	}

	async getCameras() {
		const user = await this.userAuth.getUser()

		this.cameras = user.cameras
		console.log(this.cameras)
		this.camerasSubject.next(this.cameras)
	}
}
